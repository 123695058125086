import './releated-slider.scss';
import Swiper, { Navigation, Pagination } from 'swiper';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

class RelatedSlider {
    constructor ($element) {
        this.$relatedSlider = $element;
        this.$slider = this.$relatedSlider.querySelector('[data-related-slider="slider"]');
        this.$slidePrev = this.$relatedSlider.querySelector('[data-related-slider="prev"]');
        this.$slideNext = this.$relatedSlider.querySelector('[data-related-slider="next"]');
        this.$shopSlider = this.$relatedSlider.querySelector('[shop-slider]');

        this.slider = undefined;
    }

    initialize () {
        this.initSlider();
        this.setEvents();
    }

    setEvents () {
    }

    initSlider () {
        if (this.$shopSlider) {
            // Additonal config for shop slider
            this.spaceBetween = 5;
            this.autoHeight = true;
            this.breakpoints = {
                320: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 3
                }
            };
        } else {
            // Default config for slider
            this.spaceBetween = 30;
            this.autoHeight = false;
            this.breakpoints = {
                320: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 3
                }
            };
        }

        if (this.$slider) {
            this.slider = new Swiper(this.$slider, {
                modules: [Navigation, Pagination],
                slidesPerView: 1,
                autoHeight: this.autoHeight,
                spaceBetween: this.spaceBetween,
                speed: 500,
                watchOverflow: true,
                navigation: {
                    prevEl: this.$slidePrev,
                    nextEl: this.$slideNext
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                breakpoints: this.breakpoints
            });

            setTimeout(() => {
                this.$slider.classList.add('has--height');
            }, 2000);
        }
    }
}

export { RelatedSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $relatedSlider = $context.querySelectorAll('[data-related-slider="root"]');

        for (let i = 0; i < $relatedSlider.length; i++) {
            const $$relatedSlider = new RelatedSlider($relatedSlider[i]);
            $$relatedSlider.initialize();
        }
    }
});
